import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { getCslData, selectCslData, getLoadingState} from '../../redux/slices/cslSlice';
import { OrcaTable } from '../../Components/common/OrcaTable';
import store from '../../redux/store';

export const ConsolidatedSuggestionLibrary = () => {
  const data = useSelector(selectCslData);
  const loading = useSelector(getLoadingState);

  useEffect(() => {
    store.dispatch(getCslData());
  }, []);

  return (
    <>
    <OrcaTable loading={loading} rows={data} showFilters title='Consolidated Insights Library' filterColumns={['capability', 'insight_key', 'requester', 'brand', 'indication', 'insight_category', 'br_text_type', 'insight_status']} displayKey={{'insight_key':{'display': true}, 'requested_sprint':{'display': true}, 'br_insight_type':{'display': true}, 'cardnumber':{'display':true, 'minWidth':'100px'}, 'insight_status':{'display': true}, 'capability':{'display': true}, 'brand':{'display': true}, 'indication':{'display': true}, 'insight_category':{'display': true, 'minWidth': '400px'}, 'ta_priority':{'display': true, 'minWidth':'250px'}, 'business_rule':{'display': true, 'minWidth': '400px'},'insight_graph_metric_key':{'display': true, 'minWidth': '250px'}, 'bia_metrics':{'display': true, 'minWidth':'400px'}, 'bia_metric_based_on':{'display': true, 'minWidth':'250px'}, 'other_metrics':{'display': true, 'minWidth':'400px'}, 'trigger_criteria':{'display': true, 'minWidth':'400px'},'bia_trigger_based_on':{'display':true,'minWidth':'250px'}, 'new_metrics_ingest':{'display': true, 'minWidth':'240px'}, 'cloud_tag':{'display': true, 'minWidth':'200px'}, 'segment':{'display': true}, 'refresh_freq':{'display': true}, 'br_text_type':{'display': true}, 'br_insight_text':{'display': true, 'minWidth':'400px'}, 'br_suggestion_text':{'display': true, 'minWidth':'400px'}, 'fcap':{'display': true, 'minWidth':'100px'}, 'status': { 'display': true, 'title': 'Request Status' }, 'sales_force_team':{'display': true, 'minWidth':'300px'}}} cloneModal updateModal viewModal />
    </>
  );
};
